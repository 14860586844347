import React from "react"

import { Flex, Spacer, Wrapper } from "../components/containers"
import SEO from "../components/seo"
import { Hero, Paragraph } from "../components/type"

const NotFoundPage = () => (
  <Wrapper>
    <SEO title="404: Not found" />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100%" width="100%">
      <Hero color="primary" fontSize={[4, 5, 6]}>...Oops</Hero>
      <Spacer my={3} />
      <Paragraph fontFamily="body" >This page does not exist.</Paragraph>
    </Flex>
  </Wrapper>
)

export default NotFoundPage
